import React from 'react';
import webd from '../stock/webd.jpg';
import mleng from '../stock/mleng.jpg';
import analyst from '../stock/analyst.jpg';
import './Courses.css';

const Courses = () => {
  const courseData = [
    {
      title: 'Machine Learning',
      description: 'Unlock the secrets of algorithms that empower systems to think and learn like humans—minus the existential crises.',
      img: mleng,
      duration: '12 months',
      industryDemand: 'High',
      detailedDescription: 'In this course, explore the fascinating world of Machine Learning. You will learn how to build intelligent models that can predict, classify, and interact. Equip yourself with skills that are in high demand in today’s data-driven world, and become the data wizard companies are searching for!',
      altText: 'Machine Learning Course Image',
    },
    {
      title: 'Web Development',
      description: 'Craft stunning web experiences that are faster than a caffeinated squirrel. Master HTML, CSS, JavaScript, and more!',
      img: webd,
      duration: '12 months',
      industryDemand: 'Very High',
      detailedDescription: 'Dive into the essentials of web development and become proficient in creating responsive, user-friendly websites. This course covers everything from the fundamentals to advanced frameworks like React and Angular, empowering you to bring your web ideas to life!',
      altText: 'Web Development Course Image',
    },
    {
      title: 'Business Analytics',
      description: 'Transform data into actionable insights that drive business decisions with precision.',
      img: analyst,
      duration: '12 months',
      industryDemand: 'High',
      detailedDescription: 'Step into the world of Business Analytics where you’ll learn to interpret and visualize data. This course equips you with the skills to analyze trends, generate reports, and make data-driven recommendations, preparing you for a career in one of the fastest-growing fields.',
      altText: 'Business Analytics Course Image',
    },
  ];

  return (
    <section id="courses" className="courses-section">
      <h2 className="courses-title">Our Courses: Where Learning Meets Fun</h2>
      <p className="courses-intro">
        Welcome to Enack Academy! Are you ready to embark on a learning journey that’s enlightening and fun? Our courses are designed to be as engaging as they are educational. Dive into the future with us and discover why our courses are the talk of the town!
      </p>
      <div className="courses-container">
        {courseData.map((course) => (
          <div key={course.title} className="course-card">
            <img src={course.img} alt={course.altText} className="course-image" />
            <div className="course-details">
              <h3 className="course-title">{course.title}</h3>
              <p className="course-description">{course.description}</p>
              <p className="course-duration"><strong>Duration:</strong> {course.duration}</p>
              <p className="course-demand"><strong>Industry Demand:</strong> {course.industryDemand}</p>
              <p className="course-detailed-description">{course.detailedDescription}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Courses;
