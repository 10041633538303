import React from 'react';
import { motion } from 'framer-motion';
import './features.css'; // Ensure this CSS file contains the updated styles
import cert from '../stock/cert.jpg';
import Flexible from '../stock/flexible.jpg';
import inst from '../stock/inst.jpg';
import need from '../stock/need.jpg';

const FeatureCard = ({ feature, delay }) => (
  <motion.article
    className="feature-card"
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.6, delay }}
    aria-labelledby={`${feature.id}-heading`}
  >
    <figure className="feature-card-image">
      <img src={feature.img} alt={feature.title} loading="lazy" />
    </figure>
    <div className="feature-card-content">
      <h3 id={`${feature.id}-heading`} className="feature-title">{feature.title}</h3>
      <p className="feature-description">{feature.description}</p>
      <ul className="feature-advantages">
        {feature.advantages.map((advantage, index) => (
          <li key={`${feature.id}-advantage-${index}`}>{advantage}</li>
        ))}
      </ul>
    </div>
  </motion.article>
);

const Features = () => {
  const featureData = [
    {
      id: 'expert-instructors',
      title: 'Expert Instructors',
      description: 'Learn from industry leaders and gain valuable insights from their experiences.',
      img: inst,
      advantages: [
        'Access to cutting-edge resources and industry knowledge.',
        'Stay updated with the latest trends and technologies.',
      ],
    },
    {
      id: 'flexible-learning',
      title: 'Flexible Learning',
      description: 'Learn at your own pace with courses designed for various learning styles.',
      img: Flexible,
      advantages: [
        'Courses that fit your schedule and learning preferences.',
        'Effective learning tailored to individual needs.',
      ],
    },
    {
      id: 'certifications',
      title: 'Certifications',
      description: 'Earn recognized certifications upon completion to advance your career.',
      img: cert,
      advantages: [
        'Boost your career prospects with industry-recognized certifications.',
        'Enhance your job marketability and career growth opportunities.',
      ],
    },
    {
      id: 'ai-education',
      title: 'Why AI is Needed in Today\'s Education',
      description: 'Explore how AI transforms learning and teaching, making education more effective and accessible.',
      img: need,
      advantages: [
        'Personalized Learning.',
        'Intelligent Tutoring.',
        'Automated Grading.',
        'Enhanced Accessibility.',
        
      ],
    },
  ];

  return (
    <section id="features" className="features-container" aria-labelledby="features-heading">
      <header>
        <h2 id="features-heading">Our Key Features</h2>
      </header>
      <div className="features-wrapper">
        {featureData.map((feature, index) => (
          <FeatureCard feature={feature} key={feature.id} delay={index * 0.3} />
        ))}
      </div>
    </section>
  );
};

export default Features;
