import React, { useEffect } from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Features from './components/Features';
import Courses from './components/courses';
import ChooseUs from './components/Chooseus';
import Testimonials from './components/Testimonials';
import FAQ from './components/faq';
import Footer from './components/Footer';
import WhatsAppButton from './components/whatsapp';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import './App.css';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD-p7XIS_5I1ZfhHhM2WfhAh2rnCJFg4O8",
  authDomain: "enackapp.firebaseapp.com",
  projectId: "enackapp",
  storageBucket: "enackapp.appspot.com",
  messagingSenderId: "273414081103",
  appId: "1:273414081103:web:88eff315bf3f5ababb383a",
  measurementId: "G-5F5SGEVCWZ"
};

const App = () => {
  useEffect(() => {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    // Request permission for notifications
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');

        // Get the registration token
        getToken(messaging, { vapidKey: 'BC_agzxpj_udcpXdELo2kX5ZGZHUQsFG315T8vp-O17IvLhCm0G0pyCwVS6tOzhecONlWenEvz-fb3ahNSkJZj8' })
          .then((currentToken) => {
            if (currentToken) {
              console.log('Token generated:', currentToken);
              // Here you can send the token to your server
              // Example: 
              // fetch('/your-server-endpoint', {
              //   method: 'POST',
              //   headers: {
              //     'Content-Type': 'application/json',
              //   },
              //   body: JSON.stringify({ token: currentToken }),
              // });
            } else {
              console.log('No registration token available. Request permission to generate one.');
            }
          })
          .catch((err) => {
            console.error('An error occurred while retrieving token: ', err);
          });
      } else {
        console.log('Unable to get permission to notify.');
      }
    });
  }, []);

  return (
    <div className="App">
      <Header />
      <HeroSection />
      <Features />
      <Courses />
      <ChooseUs />
      <WhatsAppButton />
      <Testimonials />
      <FAQ />
      <Footer />
    </div>
  );
};

export default App;
