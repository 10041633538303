import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import './HeroSection.css';
import hero from '../stock/hero.jpg';
import CourseSelection from './CourseSelection'; // Import the CourseSelection component

const HeroSection = () => {
  const [showCourseSelection, setShowCourseSelection] = useState(false);

  // Animation setup for fading in the hero section
  const fade = useSpring({ opacity: 1, from: { opacity: 0 }, delay: 200 });

  // Define the URLs for different courses
  const courseFormURLs = {
    'Machine Learning': 'https://docs.google.com/forms/d/1xCyPwDSGKira7VuBb3-Vjg0_vRY1V2PNXB0X6USNyVs/viewform',
    'Web Development': 'https://docs.google.com/forms/d/e/1FAIpQLSdF8j3TXncX_WUS90iUEtpT1b4HU7sm04Rjp6G9vX3XhZl4lQ/viewform',
    'Business Analytics': 'https://docs.google.com/forms/d/e/1FAIpQLSdJ3VoGgUL47vSwdIdAQoadp6nbouIPFWMwIhMsZO6GMI-CWw/viewform',
  };

  const handleCourseSelection = (course) => {
    const formURL = courseFormURLs[course];
    if (formURL) {
      window.open(formURL, '_blank');
    } else {
      console.error('No URL found for the selected course:', course);
    }
  };

  return (
    <animated.div style={fade} className="hero-section">
      <div className="hero-image">
        <img src={hero} alt="AI revolutionizing education" />
      </div>
      <div className="hero-content">
        <h1>Unlock the Power of AI for Education</h1>
        <p>
          Artificial Intelligence (AI) is transforming how we learn. Discover personalized learning experiences, get instant feedback, and explore innovative ways to acquire knowledge.
        </p>
        {!showCourseSelection ? (
          <>
            <button
              className="cta-button"
              onClick={() => setShowCourseSelection(true)}
              aria-label="Explore Courses"
            >
              Explore Courses
            </button>
            <p className="intro-text">
              Choose a course to see detailed information and start your journey.
            </p>
          </>
        ) : (
          <CourseSelection onSelectCourse={handleCourseSelection} />
        )}
      </div>
    </animated.div>
  );
};

export default HeroSection;
