import React, { useState } from 'react';
import './CourseSelection.css'; // Ensure this CSS file contains the updated styles

const CourseSelection = ({ onSelectCourse }) => {
  const [selectedCourse, setSelectedCourse] = useState('');

  const handleCourseSelect = (course) => {
    setSelectedCourse(course);
    onSelectCourse(course);
  };

  return (
    <div className="course-selection">
      <h2>Select a Course</h2>
      <div className="course-options">
        <button 
          className={`course-button ${selectedCourse === 'Machine Learning' ? 'selected' : ''}`} 
          onClick={() => handleCourseSelect('Machine Learning')}
        >
          Machine Learning
        </button>
        <button 
          className={`course-button ${selectedCourse === 'Web Development' ? 'selected' : ''}`} 
          onClick={() => handleCourseSelect('Web Development')}
        >
          Web Development
        </button>
        <button 
          className={`course-button ${selectedCourse === 'Business Analytics' ? 'selected' : ''}`} 
          onClick={() => handleCourseSelect('Business Analytics')}
        >
          Business Analytics
        </button>
      </div>
      {selectedCourse && (
        <p className="confirmation-message">You have selected: <strong>{selectedCourse}</strong>. Thank you!</p>
      )}
    </div>
  );
};

export default CourseSelection;
