import React, { useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import sample from '../stock/sample.jpg'; // Ensure the image path is correct
import './Testimonials.css'; // Import updated CSS

const Testimonials = () => {
  const testimonialsContainerRef = useRef(null);

  const testimonialsData = [
    {
      name: 'John Doe',
      image: sample,
      quote: 'I had a great experience with this platform. The teacher was very friendly and helpful. I would highly recommend it to anyone looking for online education.', 
      profession: 'Software Engineer',
    },
    {
      name: 'Jane Smith',
      image: sample,
      quote: 'The courses are comprehensive and engaging. The support team is always there to help. Highly recommended!', 
      profession: 'Data Scientist',
    },
    {
      name: 'Michael Johnson',
      image: sample,
      quote: 'I learned a lot and the platform exceeded my expectations. The interactive content made learning enjoyable.', 
      profession: 'Product Manager',
    },
    {
      name: 'Emily Davis',
      image: sample,
      quote: 'A fantastic learning experience with top-notch materials and instructors. I feel more confident in my skills.', 
      profession: 'UX Designer',
    },
    {
      name: 'David Wilson',
      image: sample,
      quote: 'An excellent platform for professionals looking to upskill. The courses are up-to-date with industry standards.', 
      profession: 'Software Developer',
    },
    {
      name: 'Olivia Taylor',
      image: sample,
      quote: 'The course content is very relevant, and the instructors are experts in their fields. Highly recommend for career growth.', 
      profession: 'Data Analyst',
    },
    {
      name: 'Sophia Anderson',
      image: sample,
      quote: 'Great platform for anyone looking to enhance their knowledge. The interactive approach made learning easy and effective.', 
      profession: 'Software Engineer',
    },
    {
      name: 'Emma Brown',
      image: sample,
      quote: 'The platform is user-friendly, and I learned so much in a short time. Would definitely recommend it to my peers.', 
      profession: 'Marketing Specialist',
    },
    {
      name: 'Liam Johnson',
      image: sample,
      quote: 'Exceptional courses with real-world applications. The instructors are incredibly knowledgeable!', 
      profession: 'Business Analyst',
    },
    {
      name: 'Ava Wilson',
      image: sample,
      quote: 'The community is supportive, and I felt motivated throughout my learning journey. Highly recommended!', 
      profession: 'Graphic Designer',
    },
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (testimonialsContainerRef.current) {
        const totalWidth = testimonialsContainerRef.current.scrollWidth - testimonialsContainerRef.current.clientWidth;
        testimonialsContainerRef.current.scrollLeft += 300; // Adjust scrolling speed as needed
        if (testimonialsContainerRef.current.scrollLeft >= totalWidth) {
          testimonialsContainerRef.current.scrollLeft = 0; // Reset scroll
        }
      }
    }, 4000); // Adjust slide interval as needed

    return () => clearInterval(intervalId);
  }, []);

  return (
    <section id="testimonials" className="testimonials-section">
      <h2>What Our Students Say</h2>
      <div className="testimonials-container" ref={testimonialsContainerRef} aria-label="Student testimonials">
        {testimonialsData.map((testimonial, index) => (
          <animated.article key={index} className="testimonial">
            <blockquote className="testimonial-content">
              <p>{testimonial.quote}</p>
            </blockquote>
            <div className="testimonial-author">
              <div className="author-image">
                <img src={testimonial.image} alt={`Photo of ${testimonial.name}`} />
              </div>
              <div className="author-info">
                <h4>{testimonial.name}</h4>
                {testimonial.profession && <p>{testimonial.profession}</p>}
              </div>
            </div>
          </animated.article>
        ))}
      </div>
    </section>
  );
};

export default Testimonials;
