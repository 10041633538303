import React, { useState } from 'react';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import './faq.css';
import { Helmet } from 'react-helmet';

const questions = [
  {
    id: 1,
    question: 'What is Enack?',
    answer: 'Enack is an AI-powered educational platform designed to provide personalized learning experiences and support a variety of educational needs through advanced algorithms and data analysis.',
  },
  {
    id: 2,
    question: 'How do I get started with Enack?',
    answer: 'To get started with Enack, sign up for an account on our website. Once registered, you can explore and enroll in courses tailored to your learning preferences.',
  },
  {
    id: 3,
    question: 'What are the benefits of using Enack?',
    answer: 'Enack offers numerous benefits, including personalized learning paths, instant feedback, adaptive learning technologies, and a vast range of courses and resources to enhance your educational journey.',
  },
  {
    id: 4,
    question: 'Are there any subscription fees for Enack?',
    answer: 'Enack offers various subscription plans, including free and premium options. For detailed information about pricing and what each plan includes, please visit our pricing page on the website.',
  },
  {
    id: 5,
    question: 'Can I access Enack on mobile devices?',
    answer: 'Yes, Enack is accessible on both iOS and Android devices through our mobile app. You can also access the platform via mobile web browsers for a seamless learning experience on the go.',
  },
  {
    id: 6,
    question: 'What types of courses are available on Enack?',
    answer: 'Enack offers a diverse range of courses across various fields, including technology, business, science, arts, and more. Our platform is continuously updated with new content to meet the evolving needs of learners.',
  },
  {
    id: 7,
    question: 'How does Enack ensure the quality of its content?',
    answer: 'Enack ensures high-quality content by collaborating with industry experts, educators, and content creators. We also use feedback from users to continually improve and update our courses and materials.',
  },
  {
    id: 8,
    question: 'Can I get a refund if I am not satisfied with a course?',
    answer: 'Yes, Enack offers a refund policy for unsatisfied customers within a specified period after purchase. Please refer to our refund policy page for more details.',
  },
  {
    id: 9,
    question: 'How can I contact customer support?',
    answer: 'You can contact Enack customer support through our contact form on the website or by emailing info@enack.in. Our team is available 24/7 to assist you with any inquiries.',
  },  
  {
    id: 10,                      
    question: 'Are there any discounts available for bulk enrollments?',
    answer: 'Yes, Enack offers discounts for bulk enrollments and corporate training. For more information, please visit our corporate solutions page or contact our sales team directly.',
  },
  // New Questions
  {
    id: 11,
    question: 'What is the minimum age requirement to use Enack?',
    answer: 'Enack is designed for learners of all ages. However, users under the age of 13 must have parental consent to use our platform.',
  },
  {
    id: 12,
    question: 'Can I change my subscription plan later?',
    answer: 'Yes, you can change your subscription plan at any time through your account settings. You can upgrade or downgrade your plan based on your needs.',
  },
  {
    id: 13,
    question: 'Is there a trial period for premium subscriptions?',
    answer: 'Yes, Enack offers a free trial period for our premium subscriptions. You can sign up for the trial on our pricing page to experience premium features before committing to a subscription.',
  },
  {
    id: 14,
    question: 'How often are new courses added?',
    answer: 'New courses are added regularly to keep up with the latest trends and educational needs. We release new content on a monthly basis and notify users through our platform.',
  },
  {
    id: 15,
    question: 'Can I track my progress on Enack?',
    answer: 'Yes, Enack provides detailed progress tracking for each course you enroll in. You can view your progress, completed modules, and performance metrics in your user dashboard.',
  },
];

const FAQ = () => {
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState(questions);
  const [searchTerm, setSearchTerm] = useState('');

  const handleToggle = (id) => {
    setExpandedIndexes(prev =>
      prev.includes(id) ? prev.filter(index => index !== id) : [...prev, id]
    );
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filtered = questions.filter(({ question }) =>
      question.toLowerCase().includes(searchTerm)
    );
    setFilteredQuestions(searchTerm ? filtered : questions);
  };

  const highlightSearchTerm = (text) => {
    if (!searchTerm) return text;
    const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? <mark key={index}>{part}</mark> : part
    );
  };

  return (
    <>
      <Helmet>
        <title>Enack - Online Learning Platform</title>
        <meta name="description" content="Find answers to frequently asked questions about Enack, the AI-powered educational platform. Learn about our services, features, and more." />
        <meta name="keywords" content="Enack, FAQ, frequently asked questions, AI education, online learning" />
        <meta property="og:title" content="Enack - Online Learning Platform" />
        <meta property="og:description" content="Find answers to frequently asked questions about Enack, the AI-powered educational platform. Learn about our services, features, and more." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.enack.com/faq" />
      </Helmet>
      <div className="faq-container">
        <div className="search-bar">
          <input 
            type="text" 
            placeholder="Search FAQ" 
            value={searchTerm}
            onChange={handleSearch} 
            aria-label="Search FAQ"
          />
        </div>
        <h2>Frequently Asked Questions</h2>
        {filteredQuestions.length === 0 && (
          <p>No results found for "{searchTerm}".</p>
        )}
        <Accordion allowMultipleExpanded>
          {filteredQuestions.map((question) => (
            <AccordionItem 
              key={question.id} 
              uuid={question.id} 
              expanded={expandedIndexes.includes(question.id)}
              className="accordion-item"
            >
              <AccordionItemButton 
                onClick={() => handleToggle(question.id)}
                className="accordion-button"
                aria-expanded={expandedIndexes.includes(question.id)}
                aria-controls={`panel-${question.id}`}
                id={`button-${question.id}`}
              >
                <h3>{highlightSearchTerm(question.question)}</h3>
                <span className={`accordion-icon ${expandedIndexes.includes(question.id) ? 'expanded' : ''}`}></span>
              </AccordionItemButton>
              <AccordionItemPanel 
                id={`panel-${question.id}`} 
                aria-labelledby={`button-${question.id}`}
                className="accordion-panel"
              >
                <p>{question.answer}</p>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </>
  );
};

export default FAQ;
