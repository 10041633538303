import React from 'react';

const WhatsAppButton = () => {
    const whatsappNumber = '9266369462'; // Replace with your actual WhatsApp number

    const buttonStyle = {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        width: '60px',
        height: '60px',
        backgroundColor: '#25D366', // WhatsApp green color
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
        textDecoration: 'none',
        zIndex: 1000, // Ensure this is higher than other elements
    };

    const imgStyle = {
        width: '70%',
        height: 'auto',
    };

    return (
        <a 
            href={`https://wa.me/${whatsappNumber}`} 
            target="_blank" 
            rel="noopener noreferrer" 
            style={buttonStyle}
        >
            <img
                src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                alt="WhatsApp"
                style={imgStyle}
            />
        </a>
    );
};

export default WhatsAppButton;
