import React from 'react';
import { useSpring, animated, config } from 'react-spring';
import './Chooseus.css'; // Ensure this CSS file is created

const ChooseUs = () => {
  const fade = useSpring({ opacity: 1, from: { opacity: 0 }, delay: 200 });

  return (
    <>
      {/* Meta Tags for SEO */}
      <head>
        <title>Why Choose Our Learning Platform?</title>
        <meta name="description" content="Join our learning platform to master machine learning, web development, and database management with practical experience." />
        <meta name="keywords" content="machine learning, web development, MongoDB, PyTorch, Keras, Vue.js, Node.js, online courses" />
        <meta name="author" content="Your Name" />
        <meta property="og:title" content="Why Choose Our Learning Platform?" />
        <meta property="og:description" content="Join us to gain practical experience with machine learning, web development, and database management." />
        <meta property="og:image" content="https://yourwebsite.com/image.png" />
        <meta property="og:url" content="https://yourwebsite.com" />
        <meta name="twitter:card" content="summary_large_image" />
      </head>

      {/* Structured Data for SEO */}
      <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "EducationalOrganization",
          "name": "Your Learning Platform",
          "description": "A comprehensive platform for mastering machine learning, web development, and database management.",
          "url": "https://yourwebsite.com",
          "logo": "https://yourwebsite.com/logo.png",
          "sameAs": [
            "https://www.facebook.com/yourprofile",
            "https://twitter.com/yourprofile"
          ]
        }
      `}
      </script>

      <animated.section style={fade} className="choose-us-container" aria-labelledby="choose-us-heading">
        <animated.h2 id="choose-us-heading" style={fade} className="choose-us-title">Why Choose Our Learning Platform? 🌟</animated.h2>
        <animated.p className="choose-us-introduction" style={fade}>
          Our comprehensive courses are designed to equip you with essential skills in machine learning, web development, and database management. Join us to gain practical experience with cutting-edge technologies used in the industry.
        </animated.p>
        <animated.div style={fade} className="choose-us-content">
          {featuresData.map((feature, index) => (
            <ChooseUsContentItem
              key={index}
              title={feature.title}
              description={feature.description}
              logo={feature.logo}
              delay={index * 200} // Staggering effect
            />
          ))}
        </animated.div>
      </animated.section>
    </>
  );
};

const ChooseUsContentItem = ({ title, description, logo, delay }) => {
  const fade = useSpring({ 
    opacity: 1, 
    transform: 'translateY(0)', 
    from: { opacity: 0, transform: 'translateY(20px)' }, 
    config: config.stiff, // Stiff transition for a snappier effect
    delay 
  });

  return (
    <animated.article style={fade} className="choose-us-content-item">
      <animated.img src={logo} alt={`${title} logo`} className="choose-us-logo" />
      <animated.h3 style={fade} className="choose-us-content-title">{title}</animated.h3>
      <animated.p style={fade} className="choose-us-content-description">{description}</animated.p>
    </animated.article>
  );
};

// Sample data for features with logos
const featuresData = [
  {
    title: "Master Machine Learning with Scikit-learn 📚",
    description: "Dive into machine learning and learn how to build effective models using Scikit-learn, a widely-used Python library for data analysis and modeling.",
    logo: "https://upload.wikimedia.org/wikipedia/commons/0/05/Scikit_learn_logo_small.svg"
  },
  {
    title: "Deep Learning with PyTorch 🔥",
    description: "Explore deep learning concepts and create powerful neural networks using PyTorch, known for its flexibility and dynamic computation graph.",
    logo: "https://raw.githubusercontent.com/pytorch/pytorch/main/docs/source/_static/img/pytorch-logo-dark.svg"
  },
  {
    title: "Build Neural Networks with Keras 🌐",
    description: "Understand the fundamentals of neural networks and leverage Keras, a high-level API, to implement deep learning models efficiently.",
    logo: "https://keras.io/img/logo.png"
  },
  {
    title: "Web Development with Vue.js 🌈",
    description: "Learn to develop dynamic, interactive web applications using Vue.js, a progressive JavaScript framework that is easy to integrate.",
    logo: "https://vuejs.org/images/logo.png"
  },
  {
    title: "Server-side Development with Node.js 🟢",
    description: "Become proficient in server-side programming with Node.js, enabling you to build scalable network applications using JavaScript.",
    logo: "https://nodejs.org/static/images/logo.svg"
  },
  {
    title: "Database Management with MongoDB 🍃",
    description: "Gain expertise in managing NoSQL databases with MongoDB, a flexible and scalable database solution ideal for modern applications.",
    logo: "https://www.vectorlogo.zone/logos/mongodb/mongodb-icon.svg"
  },
];

export default ChooseUs;
